<template>
  <v-container class="p-4">
    <loading-flux :value="loading"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-3">
        Trabajadores
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center" class="mt-1"> </v-row>
    <p class="text-right mt-5 pt-3 mr-5">
      Número de trabajadores total:
      {{ employeesData.count }}
    </p>
    <v-btn
      @click="openModalUpdateOrCreateEmployee(null)"
      small
      color="primary text-white"
      class="mt-5 mr-5 float-right"
    >
      Agregar Trabajador
    </v-btn>
    <v-data-table
      class="elevation-1 px-5 py-3 mt-1"
      :headers="tableHeaders"
      :items="employeesData.employees"
      :items-per-page="20"
      :loading="loadingEmployees"
      :page.sync="filters.page"
      loading-text="Cargando empleados"
      item-key="id"
      hide-default-footer
      no-data-text="Sin trabajadores"
      @page-count="pageCount = $event"
    >
      <template v-slot:top>
        <v-toolbar flat class="w-50 pt-5">
          <v-text-field
            label="Busqueda"
            v-model="filters.filter"
            @input="($event) => getEmployeesData()"
            class="mb-5"
            clearable
            placeholder="Buscar trabajador"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalUpdateOrCreateEmployee(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="deleteEmployee(item)"
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-chip
          :color="item.is_active ? 'primary' : 'red darken-2'"
          class="text-white"
        >
          {{ item.is_active ? "Activo" : "Inactivo" }}</v-chip
        >
      </template>
      <template v-slot:item.is_material_requester="{ item }">
        <v-chip
          :color="item.is_material_requester ? 'primary' : 'red darken-2'"
          class="text-white"
        >
          {{ item.is_material_requester ? "Si" : "No" }}</v-chip
        >
      </template>
      <template v-slot:item.substitute="{ item }">
        <span>
          {{
            item.substitute
              ? `${item.substitute.name} (${item.substitute.rut})`
              : "Sin suplente"
          }}</span
        >
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="employeesData.pages"
      ></v-pagination>
    </div>
    <update-or-create-employee
      :modalAction="dialogUpdateOrCreateEmployees"
      :closeModalEmployee="closeModalUpdateOrCreateEmployee"
      :currentEmployee="currentEmployee"
      @employeeUpdated="getEmployeesData(filters.page)"
    ></update-or-create-employee>
  </v-container>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { mapActions, mapGetters } from "vuex";
import config from "@/config";
import UpdateOrCreateEmployee from "./UpdateOrCreateEmployee.vue";

export default {
  components: { UpdateOrCreateEmployee },
  watch: {
    "filters.page": function(page) {
      this.getEmployeesData(page);
    },
  },
  mounted() {
    this.getEmployeesData();
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Trabajadores",
          disabled: true,
          href: "/mantenedores/trabajadores",
        },
      ],
      dialogUpdateOrCreateEmployees: false,
      currentEmployee: null,
      loadingEmployees: false,
      filters: {
        page: 1,
        filter: "",
      },
      debounce: null,
      debounceTime: 500,
      pageCount: 0,
      tableHeaders: [
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
        {
          text: "Nombre",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Apellido",
          align: "center",
          sortable: false,
          value: "paternal_last_name",
        },
        {
          text: "RUT",
          align: "center",
          sortable: false,
          value: "rut",
        },
        {
          text: "Cargo",
          align: "center",
          sortable: false,
          value: "position.name",
        },
        {
          text: "Activo",
          align: "center",
          sortable: false,
          value: "is_active",
        },
        {
          text: "Suplente",
          align: "center",
          sortable: false,
          value: "substitute",
        },
        {
          text: "Solicita material",
          align: "center",
          sortable: false,
          value: "is_material_requester",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getEmployees: "maintainers/getEmployees",
      sendDeleteEmployee: "maintainers/deleteEmployee",
    }),
    async getEmployeesData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = await setTimeout(async function() {
        self.loadingEmployees = true;
        await self.getEmployees({
          password: config.password_manteiners,
          page: page - 1,
          filter: self.filters.filter ?? undefined,
        });
        self.loadingEmployees = false;
      }, self.debounceTime);
    },
    openModalUpdateOrCreateEmployee(employee) {
      this.currentEmployee = employee;
      this.dialogUpdateOrCreateEmployees = true;
    },
    closeModalUpdateOrCreateEmployee($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogUpdateOrCreateEmployees = false;
        this.currentEmployee = null;
      }
    },
    async deleteEmployee(item) {
      let self = this;
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estás seguro de eliminar este empleado`,
          text: `${item.name} ${item.paternal_last_name} - ${item.rut}`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: `Eliminar`,
          confirmButtonColor: "red",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await self.sendDeleteEmployee({
              password: config.password_manteiners,
              employee_id: item.id,
            });
            if (response.status == 200) {
              self.$swal.fire({
                icon: "success",
                title: "Excelente",
                text: `Empleado eliminado con éxito`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
              self.getEmployeesData(self.filters.page);
            }
            if (response.status == 500) {
              self.$swal.fire({
                icon: "error",
                title: "Error",
                text:
                  "Ha ocurrido un error en el servidor, intentalo nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      loading: "maintainers/loading",
      employeesData: "maintainers/employees",
    }),
  },
};
</script>

<style></style>
