<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="900px"
      scrollable
      @keydown="executeCloseModalEmployee"
      @click:outside="executeCloseModalEmployee"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            {{ currentEmployee ? "Actualizar" : "Agregar" }} trabajador
            {{
              currentEmployee
                ? `${currentEmployee.name} (${currentEmployee.email})`
                : ""
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form class="my-5">
            <v-row align-content="center" justify="center">
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  type="text"
                  v-model="$v.form.name.$model"
                  :error-messages="
                    $v.form.name.$invalid && submitUpload
                      ? 'Nombre es requerido'
                      : null
                  "
                  label="Nombre"
                  placeholder="Ingresa el nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  type="text"
                  v-model="$v.form.paternal_last_name.$model"
                  :error-messages="
                    $v.form.paternal_last_name.$invalid && submitUpload
                      ? 'Primer apellido es requerido'
                      : null
                  "
                  label="Primer Apellido"
                  placeholder="Ingresa el Primer Apellido"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  type="text"
                  v-model="$v.form.maternal_last_name.$model"
                  :error-messages="
                    $v.form.maternal_last_name.$invalid && submitUpload
                      ? 'Segundo Apellido es requerido'
                      : null
                  "
                  label="Segundo Apellido"
                  placeholder="Ingresa el Segundo Apellido"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  type="text"
                  v-model="$v.form.rut.$model"
                  :error-messages="
                    $v.form.rut.$invalid && submitUpload
                      ? 'RUT es requerido'
                      : null
                  "
                  label="RUT"
                  placeholder="Ingresa el RUT"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  type="email"
                  v-model="$v.form.email.$model"
                  :error-messages="
                    $v.form.email.$invalid && submitUpload
                      ? 'Correo electrónico es requerido'
                      : null
                  "
                  label="Correo electrónico"
                  placeholder="Ingresa el correo electrónico"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  :items="positions"
                  v-model="$v.form.position_id.$model"
                  item-text="name"
                  item-value="id"
                  searchable
                  label="Cargo"
                  placeholder="Selecciona un cargo"
                  :error-messages="
                    $v.form.position_id.$invalid && submitUpload
                      ? 'Cargo es requerido'
                      : null
                  "
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  :items="submanagments"
                  v-model="$v.form.submanagement_id.$model"
                  item-text="name"
                  item-value="id"
                  searchable
                  label="Subgerencia"
                  placeholder="Selecciona la subgerencia"
                  dense
                  :error-messages="
                    $v.form.submanagement_id.$invalid && submitUpload
                      ? 'Subgerencia es requerida'
                      : null
                  "
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  :items="wrappedSubstituteEmployees"
                  v-model="$v.form.substitute.$model"
                  item-text="label"
                  item-value="id"
                  @update:search-input="getSubstituteEmployeesData($event)"
                  :loading="loadingSubstitute"
                  searchable
                  label="Suplente"
                  placeholder="Selecciona un suplente"
                  dense
                  hide-details=""
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <span for="" class="mb-0">
                  ¿Trabajador está activo?
                </span>
                <v-checkbox
                  hide-details
                  dense
                  v-model="$v.form.is_active.$model"
                  class="mx-5 mt-0"
                >
                  <template v-slot:label>
                    <h5 class="mt-4">
                      Activo
                    </h5>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <span for="" class="mb-0">
                  ¿Es solicitante de materiales?
                </span>
                <v-checkbox
                  hide-details
                  dense
                  v-model="$v.form.is_material_requester.$model"
                  class="mx-5 mt-0"
                >
                  <template v-slot:label>
                    <h5 class="mt-4">
                      Solicitante
                    </h5>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            class="text-white"
            @click="updateOrCreateEmployee"
          >
            <small>
              {{ currentEmployee ? "Actualizar" : "Agregar" }} empleado
            </small>
          </v-btn>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalEmployee"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import config from "@/config";
import { mapActions, mapGetters } from "vuex";
import swal from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      required: true,
    },
    closeModalEmployee: {
      type: Function,
      default: () => {},
    },
    currentEmployee: {
      default: null,
    },
  },
  validations: {
    form: {
      name: { required },
      paternal_last_name: { required },
      maternal_last_name: { required },
      position_id: { required },
      email: { required },
      rut: { required },
      submanagement_id: { required },
      is_material_requester: {},
      is_active: {},
      substitute: {},
    },
  },
  data() {
    return {
      form: {
        name: "",
        paternal_last_name: "",
        maternal_last_name: "",
        position_id: null,
        email: "",
        rut: "",
        submanagement_id: null,
        is_material_requester: false,
        is_active: true,
        substitute: null,
      },
      submitUpload: false,
      debounce: null,
      debounceTime: 500,
      loadingSubstitute: false,
    };
  },
  mounted() {
    this.getPositions({
      password: config.password_manteiners,
    });
    this.getSubmanagments({
      password: config.password_manteiners,
    });
    this.getSubstituteEmployeesData();
  },
  methods: {
    ...mapActions({
      getSubstituteEmployees: "maintainers/getSubstituteEmployees",
      sendUpdateEmployee: "maintainers/updateEmployee",
      createEmployee: "maintainers/createEmployee",
      getPositions: "maintainers/getPositions",
      getSubmanagments: "maintainers/getSubmanagments",
    }),
    async getSubstituteEmployeesData(search = "") {
      if (search === null || !search.includes("(")) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = await setTimeout(async function() {
          self.loadingSubstitute = true;
          await self.getSubstituteEmployees({
            password: config.password_manteiners,
            page: 0,
            filter: search ?? undefined,
          });
          self.loadingSubstitute = false;
        }, self.debounceTime);
      }
    },
    executeCloseModalEmployee($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalEmployee($event);
      }
    },
    resetForm() {
      this.form.substitute = null;
      this.form.is_active = true;
      this.form.name = "";
      this.form.paternal_last_name = "";
      this.form.maternal_last_name = "";
      this.form.position_id = null;
      this.form.email = "";
      this.form.rut = "";
      this.form.submanagement_id = null;
      this.form.is_material_requester = false;
      this.submitUpload = false;
    },
    async updateOrCreateEmployee() {
      this.submitUpload = true;
      if (!this.$v.form.$invalid) {
        let response = null;
        let payload = {
          password: config.password_manteiners,
          name: this.form.name,
          paternal_last_name: this.form.paternal_last_name,
          maternal_last_name: this.form.maternal_last_name,
          position_id: this.form.position_id,
          email: this.form.email,
          rut: this.form.rut,
          submanagement_id: this.form.submanagement_id
            ? this.form.submanagement_id
            : null,
          is_material_requester: this.form.is_material_requester,
          is_active: this.form.is_active,
          substitute_id: this.form.substitute ? this.form.substitute : null,
        };
        if (this.currentEmployee) {
          response = await this.sendUpdateEmployee({
            ...payload,
            employee_id: this.currentEmployee.id,
          });
        } else {
          response = await this.createEmployee({
            ...payload,
          });
        }
        if (response.status == 422) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: `${
              response.data?.detail
                ? response.data?.detail
                : "Error de validación"
            }`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 4000,
            timerProgressBar: true,
          });
          return;
        }
        if (response.status == 200 || response.status == 201) {
          swal.fire({
            icon: "success",
            title: "Excelente",
            text: `Empleado actualizado con éxito`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 4000,
            timerProgressBar: true,
          });
          this.resetForm();
          this.$emit("employeeUpdated");
          this.closeModalEmployee(true);
        }
        if (response.status == 500 || !response.status) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },
    setCurrentEmployee(employee) {
      this.form.is_active = employee.is_active;
      this.form.substitute = employee.substitute
        ? employee.substitute.id
        : null;
      this.form.name = employee.name;
      this.form.paternal_last_name = employee.paternal_last_name;
      this.form.maternal_last_name = employee.maternal_last_name;
      this.form.position_id = employee.position ? employee.position.id : null;
      this.form.email = employee.email;
      this.form.rut = employee.rut;
      this.form.submanagement_id = employee.submanagement
        ? employee.submanagement.id
        : null;
      this.form.is_material_requester = employee.is_material_requester;
    },
  },
  computed: {
    ...mapGetters({
      substituteEmployees: "maintainers/substituteEmployees",
      positions: "maintainers/positions",
      submanagments: "maintainers/submanagments",
    }),
    wrappedSubstituteEmployees() {
      return this.substituteEmployees.employees
        .filter((item) => item.id !== this.currentEmployee?.id)
        .map((item) => {
          return {
            label: `${item.name} (${item.email})`,
            ...item,
          };
        });
    },
  },
  watch: {
    currentEmployee: {
      handler(newEmployee) {
        if (newEmployee) {
          this.setCurrentEmployee(newEmployee);
        } else {
          this.resetForm();
        }
      },
    },
  },
};
</script>

<style></style>
